.nav-container {
  justify-content: space-between;
  padding: 7rem 0 8.7rem 6.3rem;
  position: fixed;
  top: 0;
  bottom: 0;
  :global {
    .logo {
      width: 4.9rem;
      cursor: pointer;
    }

    .profile {
      width: 5.4rem;
      height: 5.4rem;
      border-radius: 50%;
      padding: 0.61rem;
      background-color: rgba(255, 76, 0, 0.2);
      margin: auto;
      h5 {
        background-color: #ff4c00;
        width: 100%;
        height: 100%;
        display: inline-block;
        border-radius: 50%;
        color: #fff;
        line-height: 4rem;
      }
      a {
        font-size: 1.6rem;
        margin-top: 5px;
        display: block;
      }
    }
    .nav {
      cursor: pointer;
      background: transparent linear-gradient(136deg, #606eed 0%, #4c5ced 100%)
        0% 0% no-repeat padding-box;
      border-radius: 1.8rem;
      position: relative;
      padding: 1.2rem 1rem;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;

      &::after {
        width: 4.3rem;
        height: 1.4rem;
        background: #6171e5;
        border-radius: 9px;
        opacity: 1;
        filter: blur(12px);
        content: "";
        position: absolute;
        bottom: -0.6rem;
      }

      img {
        max-width: 2.5rem;
        margin: 0 auto 0.63rem;
      }

      p {
        font-family: outfit-medium;
        color: #ffffff;
        opacity: 0.8;
        font-size: 1.1rem;
      }
    }
  }
}

@media screen and (max-height: 1000px) {
  .nav-container {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .nav-container {
    position: relative;
    width: 100%;
    flex-direction: row !important;
    padding: 5rem 5rem 0rem;
  }
}
