$fonts: ('Outfit-Medium', 'Outfit-Regular', 'Outfit-SemiBold');
$font-path: "../assets/fonts";


/*Mixin for font faces*/
@mixin font-faces($font-name, $font-path) {
  @font-face {
    font-family: $font-name;
    src:url('#{$font-path}/#{$font-name}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@each $font-name in $fonts {
  @include font-faces($font-name, $font-path);
}