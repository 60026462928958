.alertBox {
  position: fixed;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  & > div {
    border-radius: 10px;
    padding: 2rem;
    background: #fefefe;
    border: 1px solid rgba(34, 34, 34, 0.2);
    display: inline-block;
    max-width: 75%;
    label {
      font-size: 1.6rem;
      font-weight: normal;
    }
  }
}
