@import "./fonts";
@import "./common.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

ol:not(article ol),
ul:not(article ul) {
  list-style: none;
}

ol,
ul {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: outfit-regular;
  color: #222;
}

html,
body {
  overflow: hidden;
}

html,
body,
#root {
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

:focus:not(:focus-visible) {
  outline: none;
}

label,
button,
select,
[type="radio"],
[type="submit"],
[type="checkbox"] {
  cursor: pointer;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio[controls],
canvas,
video {
  display: inline-block;
}

body,
button,
input,
select,
textarea {
  font-family: outfit-regular;
  color: #222;
}
::-moz-selection,
::selection {
  color: #fff;
  text-shadow: none;
}
a:focus {
  outline: thin dotted;
}
a:hover,
a:active {
  outline: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
}
label {
  cursor: pointer;
}
button,
input,
select,
textarea {
  margin: 0;
  vertical-align: middle;
}
button,
input {
  line-height: normal;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  appearance: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}
input[type="search"] {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

*:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #606eed;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(96, 110, 237, 0.6);
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-track:hover {
  background-color: #e5e5e5;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #606eed #f1f1f1;
}

/* Edge */
*::-ms-scrollbar {
  width: 6px;
}

*::-ms-thumb {
  background-color: #606eed;
  border-radius: 6px;
}

*::-ms-thumb:hover {
  background-color: rgba(96, 110, 237, 0.6);
}

*::-ms-track {
  background-color: #f1f1f1;
}

*::-ms-track:hover {
  background-color: #e5e5e5;
}

@media screen and (max-width: 1600px) {
  html {
    font-size: 55%;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 50%;
  }
}

@media screen and (max-width: 900px) {
  html {
    font-size: 40%;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 48%;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 42.5%;
  }
}
