.home-layout {
  background: url("../../assets/images/backgrounds/home-bg-1.png"),
    url("../../assets/images/backgrounds/home-bg-2.png"),
    url("../../assets/images/backgrounds/ellipse-top-bg.svg"),
    url("../../assets/images/backgrounds/ellipse-bottom-bg.svg");
  background-position:
    left 8% center,
    right 6% center,
    right -7rem top -22rem,
    left -3rem bottom -3.7rem;
  background-repeat: no-repeat;
  background-size:
    45% auto,
    45% auto,
    auto 62rem,
    auto 36rem;
}

.layout {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: #fff;
  :global {
    .top-left {
      position: fixed;
      top: 0;
      left: 0;
    }
    .top-right {
      position: absolute;
      top: 2.89rem;
      right: -6.4rem;
      width: 19rem;
      height: 5rem;
    }

    .bottom-right {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1000;
    }

    .middle {
      position: absolute;
      top: 0;
      right: 0;
    }

    .outer-wrapper {
      display: flex;
      flex-direction: row;
      height: 100%;
      .content-wrap {
        flex-grow: 1;
        margin-left: 17.5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .layout {
    :global {
      .top-left {
        position: absolute;
      }
      .outer-wrapper {
        flex-direction: column;
        .content-wrap {
          margin-left: 0;
          padding: 0 5rem;
        }
      }
    }
  }
}
