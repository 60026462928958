.hidden {
  display: none;
  visibility: hidden;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.invisible {
  visibility: hidden;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}

input:disabled,
textarea:disabled {
  background-color: rgba(217, 217, 217, 0.4);
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.text-centered {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 4.8rem;
  font-weight: 600;
}

h2 {
  font-size: 3.6rem;
}

h5 {
  font-size: 2rem;
  font-weight: normal;
}

p {
  font-size: 1.5rem;
}

label {
  font-size: 2rem;
  font-weight: bold;
}

.error {
  font-size: 1.6rem;
  color: #cd5c5c;
}

.wrapper {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  .main-content {
    padding: 6.8rem 4.4rem 6.8rem 0;
    flex-grow: 1;
    h5 {
      margin-top: 0.8rem;
    }
  }
  .sidebar {
    position: relative;
    padding: 10.6rem 4.1rem 10.6rem 3.4rem;
    background: rgba(217, 217, 217, 0.23);
    width: 32%;
  }
}

#uploadPage .main-content,
#uploadPage .sidebar {
  padding-bottom: 0;
}

.enlarged {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 100%;
  max-height: 90vh;
}

@media screen and (max-width: 1200px) {
  .wrapper .sidebar {
    width: 38%;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper .sidebar {
    width: 42%;
  }
}

@media screen and (max-width: 800px) {
  .wrapper .sidebar {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    .sidebar {
      width: 100%;
      margin-top: 5rem;
      padding-top: 5rem;
    }
    .main-content {
      padding-right: 0;
    }
  }
  #profilePage .sidebar {
    padding: 0 !important;
  }
}
